import React,{useState} from 'react';
import styled from 'styled-components';
import Button from './Button';
import Logo from './Logo';

const Section = styled.section`
width: 100vw;
background-color: #375637;

`
const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;

width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

.mobile{
  display: none;
  align-items: center;
}

@media (max-width: 64em) {
.desktop{
  display: none;
}
.mobile{
  display: inline-block;

}

}


`


const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;


@media (max-width: 64em) {
/* 1024 px */



position: fixed;
top: ${props => props.theme.navHeight};
left: 0;
right: 0;
bottom: 0;
width: 100vw;
height: ${props => `calc(100vh - ${props.theme.navHeight})`};
z-index:50;
background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
backdrop-filter: blur(2px);

transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
transition: all 0.3s ease;
flex-direction: column;
justify-content: center;

touch-action: none;


}

`


const MenuItem = styled.li`
margin: 0 1rem;
color: #ffffff;
cursor: pointer;


&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: #ffffff;
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
}

@media (max-width: 64em) {
margin: 1rem 0;
color: #000000;

&::after{
  display: none;
}

}
`
const HamburgerMenu = styled.span`
width:  ${props => props.click ? '2rem' : '1.5rem'};


height: 2px;
background-color:#ffffff;
color: #ffffff;

position: absolute;

top: 3.3rem;
left: 90%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)'  };

display: none;
justify-content: center;
align-items: center;

cursor: pointer;
transition: all 0.3s ease;

@media (max-width: 64em) {
/* 1024 px */
display: flex;

}

&::after, &::before{
  content: ' ';
  width:  ${props => props.click ? '1rem' : '1.5rem'};
  height: 2px;
  right: ${props => props.click ? '-2px' : '0'};
  background-color:#ffffff;
position: absolute;
transition: all 0.3s ease;
}

&::after{
  top: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'  };

}
&::before{
  bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'  };
}


`




const Navigation = ({accounts, setAccounts}) => {

  const isConnected = Boolean(accounts[0]);

  /*async function connectAccount(){
    
   if(!window.ethereum){
    window.addEventListener('ethereum#initialized', handleEthereum, {
      once: true,
    });
      setTimeout(handleEthereum, 3000);
   } else {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
   }
}

   
   function handleEthereum() {
     const { ethereum } = window;
     if (ethereum) {
       console.log('Ethereum successfully detected!');
     }
   }*/
   async function connectAccount(){
    if(window.ethereum){
      const accounts = await window.ethereum.request({
      method: "eth_requestAccounts"});
      setAccounts(accounts);
    }
    document.getElementById('wallet-address').textContent = accounts
   }


  const [click, setClick] = useState(false);
  
const scrollTo = (id) => {

  let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })

  setClick(!click);
}


  return (
    

    /*<Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu  click={click}  onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo('home')}  >Home</MenuItem>
          <MenuItem onClick={() => scrollTo('about')}  >About</MenuItem>
          <MenuItem onClick={() => scrollTo('roadmap')}  >Roadmap</MenuItem>
          <MenuItem onClick={() => scrollTo('showcase')}  >Showcase</MenuItem>

          <MenuItem onClick={() => scrollTo('faq')}  >Faq</MenuItem>
          <MenuItem>
            <div className="mobile">
            {isConnected ? (
              <p class='connesso'>Connected</p>
            ) : (
              <Button func={connectAccount} text="Connect Wallet" ></Button>
            )}
            </div>
          </MenuItem>
        </Menu>
          <div className="desktop">
            {isConnected ? (
              <p class='connesso'>Connected</p>
            ) : (
              <Button func={connectAccount} text="Connect Wallet" ></Button>
            )}
          </div>


      </NavBar>
    </Section>*/

    <Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu  click={click}  onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo('home')}  >Home</MenuItem>
          <MenuItem onClick={() => scrollTo('about')}  >About</MenuItem>
          <MenuItem onClick={() => scrollTo('roadmap')}  >Roadmap</MenuItem>
          <MenuItem onClick={() => scrollTo('showcase')}  >Showcase</MenuItem>
          <MenuItem onClick={() => scrollTo('team')}  >Team</MenuItem>
          <MenuItem onClick={() => scrollTo('faq')}  >Faq</MenuItem>
          <MenuItem>
            <div className="mobile">
            <a href={"mint.html"} target="_blank" rel="noreferrer" >
              <Button text="MINT" ></Button>
            </a>
            </div>
          </MenuItem>
          </Menu>
            <div className="desktop">
            <a href={"mint.html"} target="_blank" rel="noreferrer" >
                <Button text="MINT" ></Button>
            </a>
            </div>
          

      </NavBar>
    </Section>
  )
}

export default Navigation